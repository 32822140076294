import { FC, useCallback, useEffect } from 'react'
import { ApolloError } from '@apollo/client'
import { Controller, useFormContext } from 'react-hook-form'
import FormElement from 'components/form/FormElement'
import Input from 'components/form/Input'
import Typography from 'components/Typography'
import PhoneInput from 'components/form/PhoneInput'
import { IconMail, IconPerson } from 'icons'
import * as events from '../events'
import styles from '../QEV.module.scss'
import FAQ, { TLoginButtonProps } from '../components/FAQ'
import { TFormValues } from '../types'
import { validatePhoneNumber } from 'lib/phone'

type TProps = {
  loginButton?: TLoginButtonProps
  goToNextStep: () => void
  createQEVError?: ApolloError
  createQEVLoading?: boolean
}

const Register: FC<TProps> = ({
  loginButton,
  goToNextStep,
  createQEVError,
  createQEVLoading,
}) => {
  const {
    register,
    formState: { errors: formErrors },
    getValues,
    trigger,
    control,
  } = useFormContext<TFormValues>()

  const getInputStatus = (error?: string) => (error ? 'error' : undefined)

  const goFoward = useCallback(async () => {
    const valid = await trigger()

    if (valid) {
      events.onClickNextStepQEV()

      goToNextStep()
    }
  }, [])

  useEffect(() => {
    events.onLoadProfileStepQEV()
  }, [])

  return (
    <div className={styles.ecQEVAboutYouFormContainer}>
      <div>
        <Typography as="h2" variant="Title" bold>
          <span className={styles.ecQEVPink}>Quase lá,</span> finalize seu
          cadastro para receber sugestões de imóveis.
        </Typography>
        <div className={styles.ecQEVFormWrapper}>
          <FormElement
            status={getInputStatus(formErrors.name?.message)}
            title={{
              variant: 'label',
              value: 'Nome',
              htmlFor: 'name',
              required: true,
            }}
            helpText={{
              ariaDescribeId: 'name',
              value: formErrors.name?.message,
            }}
            size="big"
          >
            <Input
              status={getInputStatus(formErrors.name?.message)}
              leadingContent={{
                variant: 'icon',
                Icon: {
                  Component: IconPerson,
                  title: 'Icon',
                  color: 'grey900',
                },
              }}
              inputElementProps={{
                onFocus: () =>
                  events.onFocusProfileStepInputQEV('name', getValues('name')),
                id: 'name',
                'aria-label': 'Nome',
                ...register('name', {
                  required: 'O preenchimento desse campo é obrigatório',
                }),
                placeholder: 'Ex: Maria da Silva',
              }}
            />
          </FormElement>

          <FormElement
            status={getInputStatus(formErrors.phoneNumber?.message)}
            title={{
              variant: 'label',
              value: 'Celular',
              htmlFor: 'phoneNumber',
              required: true,
            }}
            helpText={{
              ariaDescribeId: 'phoneNumber',
              value: formErrors.phoneNumber?.message,
            }}
            size="big"
          >
            <Controller
              control={control}
              name="phoneNumber"
              defaultValue=""
              rules={{
                required: 'O preenchimento desse campo é obrigatório',
                minLength: {
                  message: 'Digite um número válido',
                  value: 14,
                },
                validate: {
                  validPhone: (phone) => {
                    const isValidNumber = validatePhoneNumber(phone)
                    return (
                      isValidNumber ||
                      'Telefone inválido. Verifique o número informado.'
                    )
                  },
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <PhoneInput
                  status={getInputStatus(formErrors.phoneNumber?.message)}
                  type="tel"
                  id="phoneNumber"
                  value={value}
                  handleOnChange={onChange}
                  handleOnBlur={onBlur}
                  aria-label="Celular"
                  placeholder="Ex: (11) 99999-9999"
                  onFocus={() =>
                    events.onFocusProfileStepInputQEV(
                      'phoneNumber',
                      getValues('phoneNumber'),
                    )
                  }
                />
              )}
            />
          </FormElement>

          <FormElement
            status={getInputStatus(formErrors.email?.message)}
            title={{
              variant: 'label',
              value: 'E-mail',
              htmlFor: 'email',
              required: true,
            }}
            helpText={{
              ariaDescribeId: 'email',
              value: formErrors.email?.message,
            }}
            size="big"
          >
            <Input
              status={getInputStatus(formErrors.email?.message)}
              leadingContent={{
                variant: 'icon',
                Icon: {
                  Component: IconMail,
                  title: 'Icon',
                  color: 'grey900',
                },
              }}
              inputElementProps={{
                id: 'email',
                'aria-label': 'E-mail',
                type: 'email',
                placeholder: 'nome@email.com',
                onFocus: () =>
                  events.onFocusProfileStepInputQEV(
                    'email',
                    getValues('email'),
                  ),
                ...register('email', {
                  required: 'O preenchimento desse campo é obrigatório',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Digite um e-mail válido',
                  },
                }),
              }}
            />
          </FormElement>
          {createQEVError && (
            <div className={styles.ecQEVBannerError}>
              <Typography as="p" variant="Paragraph">
                Erro ao enviar. Revise os seus dados e tente novamente
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div>
        <FAQ
          loginButton={loginButton}
          submitButton={{
            onClick: () => void goFoward(),
            label: 'Finalizar cadastro',
            props: {
              disabled: createQEVLoading,
            },
          }}
        />
      </div>
    </div>
  )
}

export default Register
